import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  Switch,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import EventOrGroupPopup from 'components/EventOrGroupPopup';
import ImageDrop from 'components/FileHandler';
import { isAllValid } from 'helpers/inputValidator';
import useLoadUser from 'hooks/loadUser';
import useHttpWithData from 'hooks/useHttpWithData';
import useUser from 'hooks/useUser';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { SocialEventForm } from 'types';
import GetImageUrl from '../../helpers/GetImage';
import AddressInfoForm from './AddressInfoForm';
import CapacityInfoForm from './CapacityInfoForm';
import CostInfoForm from './CostInfoForm';
import DeadlineAttendanceInfoForm from './DeadlineAttendanceInfoForm';
import StartAttendanceInfoForm from './StartAttendanceInfoForm';
import EventInfoForm from './EventInfoForm';
import FrequencyInfoForm from './FrequencyInfoForm';
import PublishAtForm from './PublishAtForm';
import './index.scss';

export const dateToString = (date: moment.Moment) => date.toISOString();

export default function CreateEventForm(props: { data?: any }) {
  const {
    send: createEvent,
    isLoading: postingNewEvent,
    status: createStatus,
    data: createData,
  } = useHttpWithData<any>({
    url: `/events`,
    method: 'post',
    config: { headers: { 'Content-Type': 'multipart/form-data' } },
  });

  const {
    send: editEvent,
    isLoading: postingEditEvent,
    status: editStatus,
    data: editData,
  } = useHttpWithData<any>({
    url: `/events`,
    method: 'put',
    config: { headers: { 'Content-Type': 'multipart/form-data' } },
  });

  const { reloadUser } = useLoadUser();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { email } = useUser();

  const { data } = props;
  const [previewUrl, setPreviewUrl] = useState(data?.imageUrl ?? '');
  const { eventId } = useParams();

  const [eventFormState, setEventFormState] = useState<SocialEventForm>({
    title: data?.title ?? '',
    hostingGroups: data?.hostingGroups ?? [],
    startTime: moment(data?.startTime),
    endTime: moment(data?.endTime),
    deadlineAttendance: data?.deadlineAttendance
      ? moment(data?.deadlineAttendance)
      : null,
    startAttendance: data?.startAttendance
      ? moment(data?.startAttendance)
      : null,
    description: data?.description ?? '',
    address: data?.address ?? '',
    file: undefined,
    frequency: data?.frequency ?? 0,
    publishDate: data?.publishDate ? moment(data?.publishDate) : null,
    maxCapacity: data?.maxCapacity ?? null,
    cost: data?.cost ?? 0,
    maxReccurance: 0,
    applyToAll: false,
    isRecurring: data?.isRecurring,
    notifications: data?.notifications ?? true,
  });
  const [openDialog, setOpenDialog] = useState(true);

  useEffect(() => {}, [openDialog]);

  const [hostingGroupName, setHostingGroupName] = useState<string>(
    data?.hostingGroups[0].name ?? ''
  );

  const validDate = (formState: SocialEventForm) => {
    return formState.startTime < formState.endTime;
  };

  const [hasErrorState, setHasErrorState] = useState({
    eventId: false,
    title: false,
    hostingGroups: false,
    startTime: false,
    endTime: validDate(eventFormState) ? false : true,
    description: false,
    address: false,
    addressLink: false,
    file: false,
    publishDate: false,
    deadlineAttendance: false,
    startAttendance: false,
  });

  function hasNewImage() {
    return eventFormState.file && eventFormState.file.size > 0;
  }

  useEffect(() => {
    if (hasNewImage()) {
      setPreviewUrl(URL.createObjectURL(eventFormState.file ?? new Blob()));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventFormState.file]);

  const handleButtonClick = async () => {
    const form = new FormData();
    form.append('eventId', eventId?.toString() ?? '0');
    form.append('title', eventFormState.title);
    form.append('startTime', dateToString(eventFormState.startTime));
    form.append('endTime', dateToString(eventFormState.endTime));
    form.append('file', eventFormState.file ?? '');
    form.append('description', eventFormState.description);
    form.append('address', eventFormState.address);
    form.append('hosts', JSON.stringify(eventFormState.hostingGroups));
    form.append('frequency', JSON.stringify(eventFormState.frequency));
    form.append('maxReccurance', JSON.stringify(eventFormState.maxReccurance));
    form.append('cost', JSON.stringify(eventFormState.cost));
    form.append('creatorEmail', eventId ? data?.creatorEmail : email);
    form.append('applyToAll', eventFormState.applyToAll.toString());
    form.append('notifications', eventFormState.notifications.toString());
    if (eventFormState.deadlineAttendance != null) {
      form.append(
        'deadlineAttendance',
        dateToString(moment(eventFormState.deadlineAttendance))
      );
    } else {
      form.append('deadlineAttendance', '');
    }
    if (eventFormState.startAttendance != null) {
      form.append(
        'startAttendance',
        dateToString(moment(eventFormState.startAttendance))
      );
    } else {
      form.append('startAttendance', '');
    }
    if (eventFormState.publishDate != null) {
      form.append('publishDate', dateToString(eventFormState.publishDate));
    } else {
      form.append('publishDate', '');
    }

    if (eventFormState.maxCapacity) {
      form.append('maxCapacity', JSON.stringify(eventFormState.maxCapacity));
    }

    if (eventId) {
      form.append('imageUrl', data.imageUrl);
      form.append('isPublished', data.isPublished);
    }
    !eventId ? await createEvent(form) : await editEvent(form);
  };

  useEffect(() => {
    if (createStatus === 201 || editStatus === 200) {
      setOpen(false);
      reloadUser();
      navigate(`/events/${createData ?? eventId}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createData, editData]);

  return (
    <Grid container gap={8} direction={'column'} p={2}>
      {data != null && eventFormState.isRecurring && (
        <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
          <DialogTitle>Recurring Activity</DialogTitle>
          <DialogContent>
            <FormControl component="fieldset">
              <RadioGroup
                row
                aria-label="applyToAll"
                name="applyToAll"
                value={eventFormState.applyToAll.toString()}
                onChange={(e) => {
                  setEventFormState((prev: SocialEventForm) => ({
                    ...prev,
                    applyToAll: e.target.value === 'true',
                  }));
                }}
              >
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label="Apply to All Recurring Activities"
                />
                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  label="Apply to Current Activity Only"
                />
              </RadioGroup>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDialog(false)}>Close</Button>
          </DialogActions>
        </Dialog>
      )}
      <Box>
        <Typography variant="h1">
          {!eventId ? 'Create' : 'Edit'} Activity
        </Typography>
        <Typography variant="subtitle1" mb={4} sx={{ color: '#838383' }}>
          Sections marked with{' '}
          <Box component="span" color="red">
            *
          </Box>{' '}
          are required
        </Typography>
      </Box>

      {data != null && eventFormState.isRecurring && (
        <FormControl component="fieldset">
          <RadioGroup
            row
            aria-label="applyToAll"
            name="applyToAll"
            value={eventFormState.applyToAll.toString()}
            onChange={(e) => {
              setEventFormState((prev: SocialEventForm) => ({
                ...prev,
                applyToAll: e.target.value === 'true',
              }));
            }}
          >
            <FormControlLabel
              value="true"
              control={<Radio />}
              label="Apply to All Recurring Activities"
            />
            <FormControlLabel
              value="false"
              control={<Radio />}
              label="Apply to Current Activity Only"
            />
          </RadioGroup>
        </FormControl>
      )}

      <EventInfoForm
        eventFormState={eventFormState}
        setEventFormState={setEventFormState}
        hasErrorState={hasErrorState}
        setHasErrorState={setHasErrorState}
        setHostingGroupName={setHostingGroupName}
        validDate={validDate}
      />
      <Divider />
      <PublishAtForm
        eventFormState={eventFormState}
        setEventFormState={setEventFormState}
        hasErrorState={hasErrorState}
        setHasErrorState={setHasErrorState}
      />
      <Divider />
      <StartAttendanceInfoForm
        eventFormState={eventFormState}
        setEventFormState={setEventFormState}
        hasErrorState={hasErrorState}
        setHasErrorState={setHasErrorState}
      />
      <Divider />
      <DeadlineAttendanceInfoForm
        eventFormState={eventFormState}
        setEventFormState={setEventFormState}
        hasErrorState={hasErrorState}
        setHasErrorState={setHasErrorState}
      />
      <Divider />
      <FrequencyInfoForm
        isCreate={eventId === undefined}
        eventFormState={eventFormState}
        setEventFormState={setEventFormState}
        hasErrorState={hasErrorState}
        setHasErrorState={setHasErrorState}
      />
      <Divider />
      <CapacityInfoForm
        eventFormState={eventFormState}
        setEventFormState={setEventFormState}
        hasErrorState={hasErrorState}
        setHasErrorState={setHasErrorState}
      />
      <Divider />
      <Typography variant="h3">Notifications</Typography>
      <Typography>
        Get notified by email when users attend and unattend the activity.
      </Typography>
      <FormControlLabel
        control={
          <Switch
            checked={eventFormState.notifications}
            onChange={(e) => {
              setEventFormState((prev) => ({
                ...prev,
                notifications: e.target.checked,
              }));
            }}
            name="notifications"
            color="primary"
          />
        }
        label={
          eventFormState.notifications
            ? 'Notifications Enabled'
            : 'Notifications Disabled'
        }
      />
      <Divider />
      <CostInfoForm
        eventFormState={eventFormState}
        setEventFormState={setEventFormState}
        hasErrorState={hasErrorState}
        setHasErrorState={setHasErrorState}
      />
      <Divider />
      <AddressInfoForm
        eventFormState={eventFormState}
        setEventFormState={setEventFormState}
        hasErrorState={hasErrorState}
        setHasErrorState={setHasErrorState}
      />
      <Divider />
      <ImageDrop
        errorState={hasErrorState}
        setErrorState={setHasErrorState}
        setFormState={setEventFormState}
        prevImage={data ? data.imageUrl : ''}
      />
      <Grid container item justifyContent="flex-end">
        <Button
          disableElevation
          disabled={!isAllValid(eventFormState, hasErrorState)}
          variant="contained"
          className="create-event-button"
          onClick={() => {
            setOpen(true);
          }}
        >
          <Box> {eventId ? 'Edit' : 'Create'} & Preview </Box>
        </Button>
      </Grid>
      {open && (
        <EventOrGroupPopup
          open={open}
          setOpen={setOpen}
          handleFormSubmit={handleButtonClick}
          formData={eventFormState}
          previewUrl={hasNewImage() ? previewUrl : GetImageUrl(data.imageUrl)}
          waitPostRequest={eventId ? postingEditEvent : postingNewEvent}
          isEvent={true}
          hostingGroupName={hostingGroupName}
        />
      )}
    </Grid>
  );
}
