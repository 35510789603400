import React, { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './index.scss';
import { isMobile } from 'react-device-detect';

interface ImageStepperCompProps {
  carouselData: any;
  initialSlide: number;
}

function ImageStepperComp({
  carouselData,
  initialSlide,
}: ImageStepperCompProps) {
  const [activeSlide, setActiveSlide] = useState(initialSlide);

  return (
    <div className="carousel-container">
      <Carousel
        className="crsl"
        selectedItem={activeSlide}
        onChange={(index: number) => setActiveSlide(index)}
        showArrows={isMobile ? false : true}
        infiniteLoop={true}
        autoPlay={isMobile ? false : true}
        centerMode={false}
        showThumbs={false}
        showIndicators={isMobile ? false : true}
      >
        {carouselData.map((imageUrl: string) => (
          <div key={imageUrl}>
            <img
              style={isMobile ? { width: '350px', height: 'auto' } : {}}
              src={imageUrl}
              alt={'img'}
            />
          </div>
        ))}
      </Carousel>
    </div>
  );
}

export default ImageStepperComp;
