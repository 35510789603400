import { Grid, Typography } from '@mui/material';
import { isMobile } from 'react-device-detect';
import GoogleMap from './GoogleMap';
import { Interweave } from 'interweave';
import { UrlMatcher } from 'interweave-autolink';

function EventInformation(props: { description: string; address: string }) {
  const { description, address } = props;
  return (
    <>
      {isMobile ? (
        <>
          <Grid item xs={12}>
            <Typography variant="h3" align="center">
              Information
            </Typography>
            <Typography
              fontSize={15}
              marginTop={'1rem'}
              sx={{
                whiteSpace: 'pre-line',
                wordBreak: 'break-word',
                '& a': {
                  color: 'blue',
                },
              }}
            >
              <Interweave
                content={description}
                matchers={[new UrlMatcher('url')]}
              />
            </Typography>
          </Grid>
          <Typography variant="h3" marginTop={'1em'}>
            Location
          </Typography>
          <Grid item xs={12} width={'100%'}>
            <GoogleMap address={address} />
          </Grid>
        </>
      ) : (
        <Grid item container direction="row" justifyContent="center">
          <Grid item md={6} xs={12}>
            <Typography
              fontSize={15}
              paddingRight={4}
              sx={{
                whiteSpace: 'pre-line',
                wordBreak: 'break-word',
                '& a': {
                  color: 'blue',
                },
              }}
            >
              <Interweave
                content={description}
                matchers={[new UrlMatcher('url')]}
              />
            </Typography>
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography variant="h3" marginBottom={'1rem'}>
              Location
            </Typography>
            <GoogleMap address={address} />
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default EventInformation;

// Maskinveien 24, Stavanger
