import { Autocomplete, Box, Grid, TextField } from '@mui/material';
import UserAvatar from 'components/UserAvatar';
import { UserWithImage } from 'types';

export default function UserSearch(props: {
  setUser: (user: UserWithImage | null) => void;
  users?: UserWithImage[];
}) {
  const { setUser, users } = props;

  return (
    <>
      <Autocomplete
        id="user-select"
        fullWidth
        options={users ?? []}
        autoHighlight
        getOptionLabel={(option) => option.fullName}
        onChange={(_: any, user: any) => setUser(user)}
        renderOption={(props, option) => (
          <Box
            component="li"
            sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
            {...props}
            key={option.appUserId}
          >
            <Grid sx={{ margin: 1 }}>
              <UserAvatar fullName={option.fullName} size={40} />
            </Grid>
            {option.fullName}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            type="text"
            onChange={() => setUser(null)}
            {...params}
            label="Select user"
            inputProps={{
              ...params.inputProps,
              autoComplete: 'off',
            }}
          />
        )}
      />
    </>
  );
}
