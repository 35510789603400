import { Grid } from '@mui/material';
import { isMobile } from 'react-device-detect';

const key = process.env.REACT_APP_MAPS_KEY;

function GoogleMap(props: { address: string }) {
  const dimensions: { height: string; width: string } = isMobile
    ? { height: '200px', width: '100%' }
    : { height: '300px', width: '100%' };
  return (
    <Grid container item width={'100%'}>
      <iframe
        {...dimensions}
        title="Map of the address"
        style={{ border: 0, borderRadius: '10px' }}
        loading="lazy"
        allowFullScreen
        referrerPolicy="no-referrer-when-downgrade"
        src={`https://www.google.com/maps/embed/v1/place?key=${key}&q=${encodeURIComponent(
          props.address
        )}&zoom=15&region=NO`}
      ></iframe>
    </Grid>
  );
}

export default GoogleMap;
