import CreateEventForm from 'components/CreateEventForm';
import LoadingIcon from 'components/LoadingIcon';
import { isAuthorizedEvent } from 'helpers/AuthorizedPage';
import useHttpGet from 'hooks/useHttpGet';
import useUser from 'hooks/useUser';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { SocialEventOverview } from 'types';

function EditEventForm(props: { eventId: string }) {
  const { eventId } = props;
  const { data, isLoading } = useHttpGet<SocialEventOverview>(
    `/events/${eventId}/overview`
  );
  const { email, authorizedEvents, isCoreMemberOrAdmin } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (!eventId || isLoading) return;
    const isAllowed =
      data?.creatorEmail === email ||
      isAuthorizedEvent(eventId, authorizedEvents) ||
      isCoreMemberOrAdmin;

    if (!isAllowed) {
      navigate(`/events/${eventId}`);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId, isLoading]);

  return data ? <CreateEventForm data={data} /> : <LoadingIcon />;
}

export default EditEventForm;
