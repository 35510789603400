import ArrowForwardIcon from '@mui/icons-material/ArrowForwardIos';
import { Button, Grid, Typography, Box } from '@mui/material';
import EventsOrGroupsPreview from 'components/EventsOrGroupsPreview';
import InformationBanner from 'components/InformationBanner';
import useHttpGet from 'hooks/useHttpGet';
import { useEffect, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useNavigate, useParams } from 'react-router';
import { useRecoilState } from 'recoil';
import { currentLocation } from 'atoms';
import { themeColorState } from 'atoms/themeState';
import '../Home/index.scss';
import GroupCardAlignment from './GroupCardAlignment';
import useUser from 'hooks/useUser';
import PullToRefresh from 'components/PullToRefresh/PullToRefresh';

interface Event {
  eventId: number;
  title: string;
  description: string;
  startTime: string;
  endTime: string;
  isAttending: boolean;
}

interface Location {
  city: string;
  color: string;
}

function LocationPage() {
  const { city } = useParams<{ city: string }>();
  const [, setThemeColor] = useRecoilState(themeColorState);
  const { data: locationData, reload: reloadLocations } =
    useHttpGet<Location[]>('/locations/design');
  const [, setCurLocation] = useRecoilState(currentLocation);
  const { userId } = useUser();
  const { data: events, reload: reloadEvents } = useHttpGet<Event[]>(
    `/locations/${city}/upcoming-events/published`
  );
  const { data: attendingEvents, reload: reloadAttending } = useHttpGet<
    Event[]
  >(userId ? `users/${userId}/events` : '');
  const [isRefreshing, setIsRefreshing] = useState(false);
  const reloadData = () => {
    reloadAttending();
    reloadEvents();
    reloadLocations();
  };
  const handleRefresh = async () => {
    setIsRefreshing(true);
    await reloadData;
    setIsRefreshing(false);
  };

  const navigate = useNavigate();

  const ViewEventsButton = () => (
    <Button
      disableElevation
      endIcon={<ArrowForwardIcon />}
      style={{ width: 'fit-content' }}
      sx={{ color: 'primary.main', margin: 'auto' }}
      onClick={() => navigate(`/events?city=${city}`)}
    >
      <Typography fontWeight="bold">View all activities</Typography>
    </Button>
  );

  useEffect(() => {
    if (city && locationData) {
      const location = locationData.find(
        (loc) => loc.city.toLowerCase() === city.toLowerCase()
      );

      if (!location) return navigate('/');

      setThemeColor(location.color);
      localStorage.setItem('theme', location.color);
      setCurLocation(city.toLowerCase());
    }
  }, [city, locationData, navigate, setThemeColor, setCurLocation]);

  const filteredEvents = useMemo(() => {
    if (!events && !attendingEvents) return [];
    if (!attendingEvents) return events;
    if (!events) return attendingEvents;
    const attendingEventIds = new Set(
      attendingEvents.map((event) => event.eventId)
    );
    const filtered = events.filter(
      (event) => !attendingEventIds.has(event.eventId)
    );
    return filtered;
  }, [events, attendingEvents]);

  const capitalizeWords = (str: string) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const displayCity = city ? capitalizeWords(city) : '';

  const MainEventsTitle = () => (
    <Typography variant="h3" marginBottom="0.5em">
      {attendingEvents && attendingEvents.length > 0
        ? 'Other activities in '
        : 'Upcoming activities in '}
      <Box
        component="span"
        sx={{
          textTransform: 'capitalize',
          backgroundColor: 'primary.light',
          display: 'inline-block',
        }}
      >
        {displayCity}
      </Box>
    </Typography>
  );
  return (
    <PullToRefresh onRefresh={handleRefresh} isRefreshing={isRefreshing}>
      <Grid container gap={isMobile ? 6 : 12} direction="column">
        {!isMobile && (
          <img alt={'Alt'} src={'/blue_line_large.svg'} className="blue-line" />
        )}
        <InformationBanner />
        <Grid
          container
          direction="column"
          style={{
            alignItems: isMobile ? 'center' : '',
          }}
        >
          {attendingEvents && attendingEvents.length > 0 && (
            <>
              <EventsOrGroupsPreview
                title="Activities you're attending"
                data={attendingEvents}
                type="activities"
                disableShowMore={false}
              />
            </>
          )}
          <EventsOrGroupsPreview
            title={<MainEventsTitle />}
            data={filteredEvents || []}
            type="activities"
            disableShowMore={true}
          />
          {ViewEventsButton()}
        </Grid>
        <GroupCardAlignment />
      </Grid>
    </PullToRefresh>
  );
}

export default LocationPage;
