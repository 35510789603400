import {
  AccessTime,
  CalendarMonthRounded,
  PlaceRounded,
} from '@mui/icons-material';
import { Box, Grid } from '@mui/material';
import moment from 'moment';
import { isMobile } from 'react-device-detect';
import JoinEventButton from './JoinEventButton';
import './index.scss';

function EventPageFooter(props: {
  eventId: number;
  address: string;
  startTime: moment.Moment;
  endTime: moment.Moment;
  deadlineAttendance: any;
  startAttendance: any;
  userId: string;
  reloadAttendance: any;
  isAttending: any;
  maxCapacity: any;
  members: any;
  isWaiting: any;
}) {
  const {
    address,
    startTime,
    endTime,
    deadlineAttendance,
    startAttendance,
    userId,
    reloadAttendance,
    isAttending,
    maxCapacity,
    members,
    isWaiting,
    eventId,
  } = props;

  return (
    <>
      <Grid
        container
        item
        xs={12}
        className="eventpage-footer"
        sx={{
          backgroundColor: 'info.main',
          padding: '1em',
        }}
      >
        {!isMobile && (
          <>
            <Grid
              container
              item
              xs={10}
              direction="row"
              columnSpacing={5}
              alignItems="center"
            >
              <Grid item>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <PlaceRounded
                    sx={{ color: 'primary.main', marginRight: '0.2em' }}
                  />
                  {address}
                </Box>
              </Grid>
              <Grid item>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <CalendarMonthRounded
                    sx={{ color: 'primary.main', marginRight: '0.2em' }}
                  />
                  {startTime.format('dddd Do of MMMM')}
                </Box>
              </Grid>
              <Grid item>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <AccessTime
                    sx={{ color: 'primary.main', marginRight: '0.2em' }}
                  />
                  {startTime.format('HH:mm') + ' - ' + endTime.format('HH:mm')}
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={2}>
              <JoinEventButton
                eventId={eventId}
                userId={userId}
                reloadAttendance={() => reloadAttendance()}
                isAttending={isAttending}
                maxCapacity={maxCapacity}
                members={members}
                isWaiting={isWaiting}
                deadlineAttendance={deadlineAttendance}
                startAttendance={startAttendance}
                isCard={false}
              />
            </Grid>
          </>
        )}
        {isMobile && (
          <Grid
            container
            item
            xs={12}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <JoinEventButton
                eventId={eventId}
                userId={userId}
                reloadAttendance={() => reloadAttendance()}
                isAttending={isAttending}
                maxCapacity={maxCapacity}
                deadlineAttendance={deadlineAttendance}
                startAttendance={startAttendance}
                members={members}
                isWaiting={isWaiting}
                isCard={false}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
}

export default EventPageFooter;
