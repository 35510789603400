import { Box, Grid, TextField, Typography } from '@mui/material';
import { isValidInput, isValidDescription } from 'helpers/inputValidator';
import { CreateSocialGroup, EditSocialGroup } from 'types';

function GroupInfoForm(props: {
  groupFormState: EditSocialGroup;
  setGroupFormState: any;
  hasErrorState: any;
  setHasErrorState: any;
}) {
  const { groupFormState, setGroupFormState, hasErrorState, setHasErrorState } =
    props;

  const onEditorChange = (e: any) => {
    setHasErrorState((prev: any) => ({
      ...prev,
      description: !isValidDescription(e.target.value),
    }));
    setGroupFormState((prev: CreateSocialGroup) => ({
      ...prev,
      description: e.target.value,
    }));
  };

  const hasValidLength = (description: string) => {
    if (description.length <= 1500) {
      return true;
    }
    return false;
  };

  return (
    <>
      <Grid container item md direction={'column'} gap={2}>
        <Typography variant="h3">
          Group information
          <Box component="span" color="red" ml={0.5}>
            *
          </Box>
        </Typography>

        <Grid container item gap={2}>
          <Grid item xs={12} md={6}>
            <TextField
              label="Group name"
              error={hasErrorState.name}
              minRows={1}
              fullWidth
              value={groupFormState.name || ''}
              onChange={(e) => {
                setHasErrorState((prev: any) => ({
                  ...prev,
                  name: !isValidInput(e.target.value),
                }));
                setGroupFormState((prev: CreateSocialGroup) => ({
                  ...prev,
                  name: e.target.value,
                }));
              }}
            />
          </Grid>
        </Grid>

        <Grid container item gap={2}>
          <Grid item xs={12} md={6}>
            {/* <RichTextEditor
              description={groupFormState.description ?? ''}
              onChange={onEditorChange}
              setHasErrorState={!isValidDescription(groupFormState.description)}
            /> */}
            <TextField
              multiline
              fullWidth
              minRows={4}
              label="Group description"
              error={hasErrorState.description}
              value={groupFormState.description}
              onChange={onEditorChange}
            />
            {!hasValidLength(groupFormState.description) && (
              <Typography color="error">
                Maximum character limit reached
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default GroupInfoForm;
