import AddIcon from '@mui/icons-material/Add';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Collapse, FormControl, MenuItem } from '@mui/material';
import Typography from '@mui/material/Typography';
import useHttpGet from 'hooks/useHttpGet';
import useUser from 'hooks/useUser';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './index.scss';

export default function GroupMenu(props: { onClose?: () => void }) {
  const navigate = useNavigate();
  const { onClose } = props;
  const { memberships, isAdmin, userId } = useUser();
  const [open, setOpen] = useState(false);
  const { data: groups, reload: reloadGroups } = useHttpGet<any[]>(
    `/social-groups/myGroups/${userId}`
  );

  useEffect(() => {
    reloadGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberships]);

  return (
    <>
      <FormControl
        fullWidth
        variant="filled"
        sx={{ minWidth: 120, marginLeft: '1.3em' }}
      >
        <MenuItem
          onClick={() => {
            setOpen((prev) => !prev);
          }}
          sx={{ pl: 4 }}
          style={{
            color: 'white',
            justifyContent: 'flex-start',
            width: '100%',
          }}
        >
          <Typography variant="body1" pr={1}>
            My groups
          </Typography>
          {open ? (
            <ExpandLess display="inline" color="inherit" />
          ) : (
            <ExpandMore display="inline" color="inherit" />
          )}
        </MenuItem>
      </FormControl>
      <Collapse in={open} orientation="vertical" timeout="auto" unmountOnExit>
        {(groups || []).map((group: any, key: number) => (
          <Typography variant="body1" mb={1} mt={0.5} key={key}>
            <span
              className="dropdown-item"
              onClick={() => {
                onClose?.();
                navigate('/groups/' + group.socialGroupId);
              }}
            >
              {group.name}
            </span>
          </Typography>
        ))}
      </Collapse>
      {isAdmin && (
        <FormControl
          fullWidth
          variant="filled"
          sx={{ minWidth: 120, marginLeft: '1.3em' }}
        >
          <MenuItem
            onClick={() => {
              onClose?.();
              navigate('/management/groups/create');
            }}
            sx={{ pl: 4 }}
            style={{
              color: 'white',
              justifyContent: 'flex-start',
              width: '100%',
            }}
          >
            <AddIcon />
            <Typography marginLeft="1em">Create group</Typography>
          </MenuItem>
        </FormControl>
      )}
    </>
  );
}
