import { useAuth0 } from '@auth0/auth0-react';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
} from '@mui/material';
import { createInitPush } from 'api/firebase';
import { isContainingValue, isValidName } from 'helpers/inputValidator';
import { swActivatedPublisher } from 'helpers/pubsub';
import useHttpGet from 'hooks/useHttpGet';
import { useEffect, useState } from 'react';
import { LocationBase } from 'types';
import { UserForm } from '.';
import useHttpWithData from 'hooks/useHttpWithData';

type RequestPermissionCallback = (
  permission: NotificationPermission
) => Promise<void> | void;

const askPermission = (callback: RequestPermissionCallback) =>
  window.Notification.requestPermission().then(callback);

function EnablePushButton() {
  const [hasPermission, setHasPermission] = useState(
    window.Notification?.permission === 'granted'
  );

  const { getAccessTokenSilently } = useAuth0();
  const [swRegistration, setSwRegistration] =
    useState<ServiceWorkerRegistration>();

  useEffect(() => {
    swActivatedPublisher.subscribeLatest('setRegistration', (e) => {
      setSwRegistration(e.payload);
    });

    return () => swActivatedPublisher.unsubscribe('setRegistration');
  }, []);

  return (
    <>
      {swRegistration && (
        <Button
          disableElevation
          disabled={hasPermission}
          variant="outlined"
          onClick={async () => {
            const initPush = createInitPush(swRegistration);
            await askPermission(async (permission) => {
              await initPush(await getAccessTokenSilently(), permission);
              setHasPermission(permission === 'granted');
            });
          }}
        >
          {!hasPermission
            ? 'Enable push notifications'
            : 'Push notifications are enabled'}
        </Button>
      )}
    </>
  );
}

function ProfileInformation(props: {
  userFormState: UserForm;
  setUserFormState: any;
  isEditing: boolean;
  setIsEditing: any;
  handleUpdateInfo: any;
  email: string;
}) {
  const {
    userFormState,
    setUserFormState,
    isEditing,
    setIsEditing,
    handleUpdateInfo,
    email,
  } = props;
  const { data: locations, isLoading: isLoadingLocations } =
    useHttpGet<LocationBase[]>('/locations');

  const {
    send: changePassword,
    // isLoading: isChangingPassword,
    error: changePasswordError,
  } = useHttpWithData<any>({
    url: '/users/reset-password',
    method: 'post',
    config: { headers: { 'Content-Type': 'application/json' } },
  });

  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleResetPassword = async () => {
    try {
      await changePassword({
        email: email,
      });
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error resetting password:', error);
    }
  };
  useEffect(() => {
    if (!userFormState.city) {
      setIsEditing(true);
    }
  }, [userFormState, setIsEditing]);

  return (
    <>
      <Grid
        container
        direction={'row'}
        marginTop={'1em'}
        marginBottom={'1em'}
        style={{
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Grid item xs={12} md={6}>
          <Grid container direction={'column'} gap={2}>
            <TextField
              error={!isValidName(userFormState.name)}
              id="outlined"
              label="Name"
              name="name"
              value={userFormState.name}
              onChange={(e) => {
                setUserFormState((prev: UserForm) => ({
                  ...prev,
                  name: e.target.value,
                }));
              }}
              disabled={!isEditing}
              fullWidth
              inputProps={{ maxLength: 30 }}
            />

            <TextField
              disabled={true}
              id="outlined"
              label="Email"
              name="email"
              value={email}
            />
            {!isLoadingLocations && (
              <FormControl fullWidth>
                <InputLabel id="cityLabel">City</InputLabel>
                <Select
                  error={
                    !isContainingValue(
                      userFormState.city,
                      locations?.map((l: any) => l.city) ?? []
                    )
                  }
                  labelId="cityLabel"
                  label="city"
                  defaultValue=""
                  value={userFormState.city}
                  onChange={(e) => {
                    setUserFormState((prev: UserForm) => ({
                      ...prev,
                      city: e.target.value,
                    }));
                  }}
                  disabled={!isEditing}
                  fullWidth
                >
                  {locations?.map((l: any) => (
                    <MenuItem
                      key={l.city.toLowerCase()}
                      value={l.city.toLowerCase()}
                    >
                      {l.city}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            <Button
              disableElevation
              variant="contained"
              color="primary"
              //disabled={noProfileChanges() || invalidChanges()}
              onClick={() =>
                isEditing ? handleUpdateInfo() : setIsEditing(true)
              }
            >
              {isEditing ? 'Save changes' : 'Edit profile'}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleResetPassword()}
            >
              Reset Password
            </Button>
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              open={snackbarOpen}
              autoHideDuration={3000}
              onClose={handleCloseSnackbar}
              message={
                changePasswordError
                  ? `Password Reset Failed`
                  : 'Reset Password Email Sent'
              }
              action={
                <Button
                  color="secondary"
                  size="small"
                  onClick={handleCloseSnackbar}
                >
                  Close
                </Button>
              }
            />
            <EnablePushButton />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default ProfileInformation;
