import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Button, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import ChatBox from 'components/ChatBox';
import EventInformation from 'components/EventInformation';
import EventsOrGroupsPreview from 'components/EventsOrGroupsPreview';
import LoadingIcon from 'components/LoadingIcon';
import MemberOverview from 'components/MemberOverview';
import { MemberCardInterface } from 'components/MemberOverview/MemberCard';
import { isAuthorizedEvent } from 'helpers/AuthorizedPage';
import useHttpGet from 'hooks/useHttpGet';
import useUser from 'hooks/useUser';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useNavigate, useParams } from 'react-router';
import EventImage from './EventImage';
import EventPageFooter from './EventPageFooter';
import InfoBar from './InfoBar';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PullToRefresh from 'components/PullToRefresh/PullToRefresh';

function EventPage() {
  const { id: eventId } = useParams();
  const { userId, isAuthenticated, authorizedEvents, email } = useUser();
  const navigate = useNavigate();
  const [isRefreshing, setIsRefreshing] = useState(false);
  const {
    data: event,
    isLoading: isLoadingEvent,
    error,
    reload: reloadEvent,
  } = useHttpGet<any | undefined>(`/events/${eventId}/overview`);

  const {
    data: attendingStatus,
    isLoading: isLoadingAttendStatus,
    reload: reloadAttendStatus,
  } = useHttpGet<boolean>(
    userId ? `/event-attendances/${userId}/${eventId}/attending` : ''
  );

  const { data: waitingListStatus, reload: reloadwaitingListStatus } =
    useHttpGet<boolean>(
      userId ? `/event-waitinglist/${userId}/${eventId}/iswaiting` : ''
    );

  const { data: waitingListBool, reload: reloadWaitingListBool } =
    useHttpGet<boolean>(userId ? `/event-waitinglist/get/${eventId}` : '');

  const { data: waitingListPosition, reload: reloadwaitingListPosition } =
    useHttpGet<number>(
      userId ? `/event-waitinglist/${userId}/${eventId}/position` : ''
    );

  const { data: eventArray } = useHttpGet<any | undefined>(
    `/events/${eventId}/next-events-in-event`
  );

  const {
    data: users,
    isLoading: isLoadingMembers,
    reload: reloadMembers,
  } = useHttpGet<MemberCardInterface[]>(
    userId ? `/events/${eventId}/users` : ''
  );

  const {
    data: waitingList,
    isLoading: isLoadingWaitingList,
    reload: reloadWaitingList,
  } = useHttpGet<MemberCardInterface[]>(
    userId ? `/events/${eventId}/waitinglist` : ''
  );
  const reloadData = () => {
    reloadAttendStatus();
    reloadMembers();
    reloadEvent();
    reloadwaitingListStatus();
    reloadWaitingListBool();
    reloadWaitingList();
    reloadwaitingListPosition();
  };

  const handleRefresh = async () => {
    setIsRefreshing(true);
    await reloadData();
    setIsRefreshing(false);
  };

  const [isShowingWaitingList, setIsShowingWaitingList] = useState(false);

  useEffect(() => {
    if (error) navigate('/page-not-found');
  }, [error, navigate]);

  useEffect(() => {
    if (event === undefined) return;
    if (!authorizedEvents.length && isAuthenticated) return;
    if (
      isAuthorizedEvent(eventId ?? '', authorizedEvents) ||
      event?.isPublished ||
      event.creatorEmail === email
    )
      return;
    navigate('/');
  }, [event, authorizedEvents, isAuthenticated, eventId, email, navigate]);

  if (isLoadingEvent || isLoadingAttendStatus) {
    return <LoadingIcon />;
  }

  return (
    <>
      <PullToRefresh onRefresh={handleRefresh} isRefreshing={isRefreshing}>
        {isMobile && (
          <Button
            variant="outlined"
            color="primary"
            onClick={() => navigate(-1)}
            startIcon={<ArrowBackIosIcon />}
          >
            Go back
          </Button>
        )}
        <Grid
          container
          direction={'column'}
          gap={4}
          style={{
            alignItems: isMobile ? 'center' : '',
          }}
        >
          <Grid item my={2}>
            <EventImage {...event} />
            <InfoBar
              {...event}
              startTime={moment(event.startTime)}
              endTime={moment(event.endTime)}
              reloadData={reloadData}
              attendances={users?.length ?? 0}
              maxCapacity={event?.maxCapacity}
              waitingList={waitingListBool}
              waitingListPosition={waitingListPosition}
              waitingListStatus={waitingListStatus}
              users={users}
            />
          </Grid>
          <EventInformation {...event} />

          {isAuthenticated && (
            <>
              <ChatBox roomId={`event-${eventId}`} />
              <Grid item xs={12}>
                <Typography variant="h3" marginTop={'1em'}>
                  See who else is attending
                </Typography>
              </Grid>
              <MemberOverview
                members={users ?? []}
                isLoading={isLoadingMembers}
              />
              {(waitingList ?? []).length >= 1 && (
                <>
                  <Button
                    disableElevation
                    endIcon={
                      isShowingWaitingList ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )
                    }
                    style={{ width: 'fit-content' }}
                    sx={{ color: 'primary.main', margin: 'auto' }}
                    onClick={() =>
                      setIsShowingWaitingList((prevState) => !prevState)
                    }
                  >
                    <Typography fontWeight="bold">
                      {isShowingWaitingList
                        ? `Hide Waiting List`
                        : `Show Waiting List`}
                    </Typography>
                  </Button>
                  {isShowingWaitingList && (
                    <>
                      <MemberOverview
                        members={waitingList ?? []}
                        isLoading={isLoadingWaitingList}
                      />
                    </>
                  )}
                </>
              )}
            </>
          )}
          <EventsOrGroupsPreview
            title="Upcoming Activities"
            data={eventArray ?? []}
            type="activities"
            disableShowMore={false}
          />
        </Grid>
        {isAuthenticated && (
          <EventPageFooter
            {...event}
            eventId={eventId}
            startTime={moment(event.startTime)}
            endTime={moment(event.endTime)}
            deadlineAttendance={moment(event.deadlineAttendance)}
            startAttendance={moment(event.startAttendance)}
            userId={userId}
            reloadAttendance={reloadData}
            isAttending={attendingStatus}
            isWaiting={waitingListStatus}
            members={users ?? []}
          />
        )}
      </PullToRefresh>
    </>
  );
}

export default EventPage;
