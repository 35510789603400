import { useAuth0 } from '@auth0/auth0-react';
import { Button, Divider, Grid } from '@mui/material';
import ConfirmationBox from 'components/ConfirmationBox/ConfirmationBox';
import EventsOrGroupsPreview from 'components/EventsOrGroupsPreview';
import LoadingIcon from 'components/LoadingIcon';
import { CompressProfilePicture } from 'helpers/CompressImage';
import useHttpDelete from 'hooks/useHttpDelete';
import useHttpGet from 'hooks/useHttpGet';
import useHttpWithData from 'hooks/useHttpWithData';
import useUser from 'hooks/useUser';
import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import ImageAndIntro from './ImageAndIntro';
import ProfileInformation from './ProfileInformation';

export interface UserForm {
  name: string;
  city: string;
  file?: Blob;
}

function User() {
  const {
    fullName: name,
    email,
    location,
    userId,
    isLoading: loadUserData,
    setCurrentUser,
  } = useUser();

  const { data: attendingEvents } = useHttpGet<any[]>(`users/${userId}/events`);
  const { data: createdEvents } = useHttpGet<any[]>(
    `users/${userId}/created-events`
  );

  const { data: socialGroups } = useHttpGet<any[]>(
    `users/${userId}/social-groups`
  );
  const { data: leadGroups } = useHttpGet<number[]>(
    `/memberships/${userId}/lead-groups`
  );

  const {
    send: updateUser,
    isLoading: isUpdatingUser,
    error,
  } = useHttpWithData<any>({
    url: `/users/update-user`,
    method: 'post',
    config: { headers: { 'Content-Type': 'multipart/form-data' } },
  });

  const { logout } = useAuth0();

  const { httpDelete } = useHttpDelete();
  const [openConfirmationBox, setOpenConfirmationBox] = useState(false);

  async function deleteUser() {
    await httpDelete(`/users/DeleteMe/${userId}`).then(() => {
      // setDisplayText(true);
      // navigate to home page
      logout({ logoutParams: { returnTo: window.origin } });
    });
  }

  const [filteredAdminGroups, setFilteredAdminGroups] = useState<any[]>([]);
  const [filteredMemberGroups, setFilteredMemberGroups] = useState<any[]>([]);

  useEffect(() => {
    if (socialGroups && leadGroups) {
      setFilteredAdminGroups(adminGroupFilter(socialGroups));
      setFilteredMemberGroups(memberGroupFilter(socialGroups));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socialGroups, leadGroups]);

  // Ignore the groups that the person is administrating, can be found in /manage
  const adminGroupFilter = (group: any[]) => {
    if (!leadGroups) return [];
    return group.filter((g: any) => leadGroups.includes(g.socialGroupId));
  };

  const memberGroupFilter = (group: any[]) => {
    if (!leadGroups) return group.filter((g: any) => g.isPublished);
    return group.filter(
      (g: any) => !leadGroups.includes(g.socialGroupId) && g.isPublished
    );
  };

  const [userFormState, setUserFormState] = useState<UserForm>({
    name: name,
    city: location,
    file: undefined,
  });

  const [isEditing, setIsEditing] = useState(false);

  const regex = new RegExp('^.*image.*$');

  const fileSelectedHandler = async (event: any) => {
    //file type check
    const file = event.target.files[0];
    if (!file || !regex.test(file.type)) {
      alert('Invalid file');
    }
    if (file?.size > 5e6 * 8) {
      alert(
        'The file size is too big. Choose a smaller file size and try again'
      );
    } else {
      const compressImage = await CompressProfilePicture(file);
      setUserFormState((prev: UserForm) => ({
        ...prev,
        file: compressImage,
      }));
    }
  };

  function getImagePreview(): string {
    if (!userFormState.file) return '';
    return regex.test(userFormState.file.type)
      ? URL.createObjectURL(userFormState.file)
      : '';
  }

  const handleUpdateInfo = async () => {
    const form = new FormData();
    form.append('appUserId', userId);
    form.append('fullName', userFormState.name);
    form.append('city', userFormState.city);
    form.append('file', userFormState.file ?? new Blob());

    await updateUser(form);

    if (!error) {
      if (userFormState.file) {
        window.location.reload();
      } else {
        setCurrentUser((prev) => ({
          ...prev,
          fullName: userFormState.name,
          location: userFormState.city,
        }));
      }

      setIsEditing(false);
    }
  };

  if (loadUserData || isUpdatingUser) {
    return <LoadingIcon />;
  }

  return (
    <>
      <Grid
        container
        direction={'column'}
        gap={2}
        style={{
          alignItems: isMobile ? 'center' : '',
        }}
      >
        <ImageAndIntro
          name={name}
          fileSelectedHandler={fileSelectedHandler}
          file={userFormState.file ? getImagePreview() : userFormState.file}
          isEditing={isEditing}
        />

        <ProfileInformation
          userFormState={userFormState}
          setUserFormState={setUserFormState}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          handleUpdateInfo={handleUpdateInfo}
          email={email}
        />

        <Divider />

        <EventsOrGroupsPreview
          title="My upcoming activities"
          data={attendingEvents ?? []}
          type="activities"
          disableShowMore={false}
        />

        <Divider />
        <EventsOrGroupsPreview
          title="Hosted activities"
          data={createdEvents ?? []}
          type="activities"
          disableShowMore={false}
        />
        <Divider />
        <EventsOrGroupsPreview
          title="My groups"
          data={memberGroupFilter(filteredMemberGroups) ?? []}
          type="groups"
          disableShowMore={false}
        />
        <Divider />
        <EventsOrGroupsPreview
          title="Administrating groups"
          data={adminGroupFilter(filteredAdminGroups) ?? []}
          type="groups"
          disableShowMore={false}
        />
      </Grid>
      <Divider />
      <Grid
        container
        direction={isMobile ? 'column' : 'row-reverse'}
        gap={2}
        style={{
          alignItems: isMobile ? 'center' : '',
        }}
      >
        <Button
          disableElevation
          disabled={!userId}
          color="primary"
          onClick={() => setOpenConfirmationBox(true)}
          variant="contained"
          sx={{
            width: '200px',
            backgroundColor: 'red',
            color: '#FFF',
            marginTop: '1em',
          }}
        >
          Delete My Account
        </Button>

        <ConfirmationBox
          open={openConfirmationBox}
          title={`Are you sure that you want to delete ${name}?`}
          content={'The user will be permanently deleted'}
          handleClose={() => {
            setOpenConfirmationBox(false);
          }}
          handleResponse={(response) => {
            if (response) deleteUser();
          }}
        />
      </Grid>
    </>
  );
}

export default User;
