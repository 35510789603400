import { Grid } from '@mui/material';
import LoadingIcon from 'components/LoadingIcon';
import useHttpGet from 'hooks/useHttpGet';
import { useParams } from 'react-router';
import IntroductionImagesComponent from './IntroductionImagesComponent';
import IntroductionTextComponent from './IntroductionTextComponent';

function InformationBanner() {
  const params = useParams();
  const { data: location, isLoading: isLoadingLocation } = useHttpGet<any>(
    params.city ? `/locations/${params.city}` : ''
  );
  if (isLoadingLocation) {
    return <LoadingIcon />;
  }

  return (
    <>
      <Grid container gap={2} justifyContent="center" alignItems="center">
        <Grid item md>
          <IntroductionTextComponent location={location} />
        </Grid>
        <Grid item md>
          <IntroductionImagesComponent location={location ?? 'Home'} />
        </Grid>
      </Grid>
    </>
  );
}

export default InformationBanner;
