import React, { useRef, useState, useEffect } from 'react';
import './PullToRefresh.scss';
import { CircularProgress, Grid } from '@mui/material';

interface PullToRefreshProps {
  onRefresh: () => Promise<void>;
  isRefreshing: boolean;
  children: React.ReactNode;
}

const PullToRefresh = ({
  onRefresh,
  isRefreshing,
  children,
}: PullToRefreshProps) => {
  const [progress, setProgress] = useState(0);
  const ref = useRef<HTMLDivElement>(null);
  const [isProgressing, setIsProgressing] = useState(false);

  useEffect(() => {
    const element = ref.current;
    if (!element) return;

    element.addEventListener('touchstart', handleTouchStart);

    function handleTouchStart(startEvent: TouchEvent) {
      const element = ref.current;
      if (!element) return;
      if (window.scrollY > 0) return;

      const initialY = startEvent.touches[0].clientY;

      element.addEventListener('touchmove', handleTouchMove);
      element.addEventListener('touchend', handleTouchEnd);

      function handleTouchMove(moveEvent: TouchEvent) {
        const element = ref.current;
        if (!element) return;

        const currentY = moveEvent.touches[0].clientY;
        const deltaY = currentY - initialY;

        if (deltaY > 0) {
          const progressValue = Math.min((deltaY / 200) * 125 - 25, 100);
          setProgress(progressValue);

          if (deltaY > 50 * 0.5) {
            setIsProgressing(true);
          } else {
            setIsProgressing(false);
          }
        } else {
          setProgress(0);
          setIsProgressing(false);
        }
      }

      function handleTouchEnd(endEvent: TouchEvent) {
        const element = ref.current;
        if (!element) return;

        const finalY = endEvent.changedTouches[0].clientY;
        const deltaY = finalY - initialY;

        if (deltaY > 200) {
          setIsProgressing(false);
          onRefresh();
        } else {
          setProgress(0);
          setIsProgressing(false);
        }

        element.removeEventListener('touchmove', handleTouchMove);
        element.removeEventListener('touchend', handleTouchEnd);
      }
    }

    return () => {
      element.removeEventListener('touchstart', handleTouchStart);
    };
  }, [onRefresh]);

  return (
    <div
      ref={ref}
      style={{
        marginTop: isRefreshing ? '40px' : '0px',
      }}
    >
      {isRefreshing && (
        <Grid justifyContent={'center'} container>
          <CircularProgress className="loading-icon" />
        </Grid>
      )}
      {isProgressing && (
        <Grid justifyContent={'center'} container>
          <CircularProgress
            className="loading-icon progressing"
            variant="determinate"
            value={progress}
          />
        </Grid>
      )}
      {children}
    </div>
  );
};

export default PullToRefresh;
