import EventOrGroupOverview from 'components/EventOrGroupOverview';
import LoadingIcon from 'components/LoadingIcon';
import PullToRefresh from 'components/PullToRefresh/PullToRefresh';
import useHttpGet from 'hooks/useHttpGet';
import { useState } from 'react';

function AllEvents() {
  const {
    data: socialEvents,
    isLoading,
    reload,
  } = useHttpGet<any>('/events/upcoming-by-city');
  const [isRefreshing, setIsRefreshing] = useState(false);

  const handleRefresh = async () => {
    setIsRefreshing(true);
    await reload();
    setIsRefreshing(false);
  };

  return (
    <>
      {isLoading && !socialEvents ? (
        <LoadingIcon />
      ) : (
        <PullToRefresh onRefresh={handleRefresh} isRefreshing={isRefreshing}>
          <EventOrGroupOverview data={socialEvents} dataType={'Activities'} />{' '}
        </PullToRefresh>
      )}{' '}
    </>
  );
}

export default AllEvents;
