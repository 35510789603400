import { Grid } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { enGB } from 'date-fns/locale';
import moment from 'moment';
import { useEffect } from 'react';
import { SocialEventForm } from 'types';

export const validTimeFrame = (eventFormState: any) => {
  const end = moment(eventFormState.endTime).dayOfYear();
  const start = moment(eventFormState.startTime).dayOfYear();
  return Math.abs(end - start) <= 7;
};
export const ValidDeadlineAttendance = (eventFormState: any) => {
  const deadline = moment(eventFormState.deadline).dayOfYear();
  const start = moment(eventFormState.startTime).dayOfYear();
  return Math.abs(deadline - start) >= 30;
};

export const ValidStartAttendance = (eventFormState: any) => {
  const startAttendance = moment(eventFormState.startAttendance).dayOfYear();
  const start = moment(eventFormState.startTime).dayOfYear();
  return Math.abs(startAttendance - start) >= 7;
};

export default function DateAndTimePicker(props: {
  setEventFormState: any;
  eventFormState: any;
  hasErrorState: any;
  setHasErrorState: any;
  validDate: any;
}) {
  const { eventFormState, validDate, hasErrorState } = props;

  const handleStartChange = (newValue: Date | null) => {
    props.setEventFormState((prev: SocialEventForm) => ({
      ...prev,
      startTime: moment(newValue),
      endTime: moment(newValue),
    }));
  };
  const handleEndChange = (newValue: Date | null) => {
    props.setEventFormState((prev: SocialEventForm) => ({
      ...prev,
      endTime: moment(newValue),
    }));
  };

  useEffect(() => {
    if (validDate(eventFormState) && validTimeFrame(eventFormState)) {
      props.setHasErrorState((prev: any) => ({
        ...prev,
        endTime: false,
      }));
    } else {
      props.setHasErrorState((prev: any) => ({
        ...prev,
        endTime: true,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.eventFormState.startTime, props.eventFormState.endTime]);

  return (
    <>
      <Grid container item md gap={2}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
          <Grid container item sm>
            <DateTimePicker
              format="dd/MM/yyyy HH:mm"
              label={`Start Date`}
              disablePast
              ampm={false}
              value={eventFormState.startTime.toDate()}
              minDateTime={new Date()}
              minutesStep={15}
              onChange={handleStartChange}
              slotProps={{
                textField: {
                  error: hasErrorState.startTime,
                  fullWidth: true,
                },
              }}
            />
          </Grid>
          <Grid container item sm>
            <DateTimePicker
              format="dd/MM/yyyy HH:mm"
              label={`End Date`}
              disablePast
              ampm={false}
              value={eventFormState.endTime.toDate()}
              minDateTime={eventFormState.startTime.toDate()}
              minutesStep={15}
              onChange={handleEndChange}
              slotProps={{
                textField: {
                  error: hasErrorState.endTime,
                  fullWidth: true,
                  helperText: (
                    <>
                      {hasErrorState.endTime
                        ? validDate(eventFormState)
                          ? 'Activities can only last for a maximum of 7 days'
                          : 'Start time must be before endtime'
                        : ''}
                    </>
                  ),
                },
              }}
            />
          </Grid>
        </LocalizationProvider>
      </Grid>
    </>
  );
}
