import EventOrGroupOverview from 'components/EventOrGroupOverview';
import LoadingIcon from 'components/LoadingIcon';
import PullToRefresh from 'components/PullToRefresh/PullToRefresh';
import useHttpGet from 'hooks/useHttpGet';
import { useState } from 'react';

function AllGroups() {
  const {
    data: socialGroups,
    isLoading,
    reload,
  } = useHttpGet<any>('/social-groups/groups-by-city/published');
  const [isRefreshing, setIsRefreshing] = useState(false);

  const handleRefresh = async () => {
    setIsRefreshing(true);
    await reload;
    setIsRefreshing(false);
  };

  return (
    <>
      {isLoading && !socialGroups ? (
        <LoadingIcon />
      ) : (
        <PullToRefresh onRefresh={handleRefresh} isRefreshing={isRefreshing}>
          <EventOrGroupOverview data={socialGroups} dataType={'Groups'} />
        </PullToRefresh>
      )}
    </>
  );
}

export default AllGroups;
