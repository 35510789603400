import { Box, CardMedia, Typography } from '@mui/material';
import './index.scss';
import moment from 'moment';

interface CardImageProps {
  imageUrl: string;
  title: string;
  year?: string;
  isEvent: boolean;
  isAttending?: boolean;
  deadline?: moment.Moment;
  startAttendance?: moment.Moment;
}

function CardImage(props: CardImageProps) {
  const { imageUrl, title, year, isAttending } = props;
  const imageHeight = '181';

  return (
    <div className="image-container">
      {isAttending && (
        <Box
          component="div"
          className="attending-tag"
          sx={{ backgroundColor: 'info.dark' }}
        >
          <Typography variant="h4" sx={{ color: 'info.main' }}>
            Attending
          </Typography>
        </Box>
      )}

      <CardMedia
        className="image"
        sx={{ objectFit: 'cover' }}
        component="img"
        image={imageUrl}
        height={imageHeight}
        src="picture"
      />
      <div className="eventcard-fader">
        <Typography className="event-name" fontSize={22}>
          {year && (
            <>
              <span className="year-color"> {year} </span> <br />
            </>
          )}
          {title}
        </Typography>
      </div>
    </div>
  );
}

export default CardImage;
