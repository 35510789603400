import { Box, Grid, TextField, Typography } from '@mui/material';
import SocialGroupSelect from 'components/SocialGroupSelect';
import { isValidInput, isValidDescription } from 'helpers/inputValidator';
import { SocialEventForm } from 'types';
import DateAndTimePicker from './DateTimePicker';
import './index.scss';
import { RichTextEditor } from 'components/RichTextEditor';

function EventInfoForm(props: {
  eventFormState: SocialEventForm;
  setEventFormState: any;
  hasErrorState: any;
  setHasErrorState: any;
  setHostingGroupName: any;
  validDate: any;
}) {
  const {
    eventFormState,
    setEventFormState,
    hasErrorState,
    setHasErrorState,
    setHostingGroupName,
    validDate,
  } = props;

  return (
    <>
      <Grid container direction={'column'} gap={2}>
        <Typography variant="h3">
          Activity Information
          <Box component="span" color="red" ml={0.5}>
            *
          </Box>
        </Typography>
        <Grid container item gap={2}>
          <Grid container item md={6}>
            <TextField
              error={hasErrorState.title}
              label="Title"
              minRows={1}
              value={eventFormState.title}
              fullWidth
              onChange={(e) => {
                setHasErrorState((prev: any) => ({
                  ...prev,
                  title: !isValidInput(e.target.value),
                }));
                setEventFormState((prev: SocialEventForm) => ({
                  ...prev,
                  title: e.target.value,
                }));
              }}
            />
          </Grid>
          <DateAndTimePicker
            eventFormState={eventFormState}
            setEventFormState={setEventFormState}
            hasErrorState={hasErrorState}
            setHasErrorState={setHasErrorState}
            validDate={validDate}
          />
        </Grid>
        <Grid container direction="row" gap={2}>
          <Grid item xs={12} md={6}>
            <RichTextEditor
              description={eventFormState.description
                .replace(/<(h[1-6]|span|img|svg|video|iframe)[^>]*>/i, '')
                .replace(/style=(".*?"|'.*?'|[^"'][^i]*)/i, '')}
              onChange={(e: any) => {
                setHasErrorState((prev: any) => ({
                  ...prev,
                  description: !isValidDescription(e.target.value),
                }));
                setEventFormState((prev: SocialEventForm) => ({
                  ...prev,
                  description: e.target.value,
                }));
              }}
              setHasErrorState={setHasErrorState}
            />
          </Grid>
          <Grid item xs={12} md>
            <SocialGroupSelect
              eventFormState={eventFormState}
              setEventFormState={setEventFormState}
              hasErrorState={hasErrorState}
              setHasErrorState={setHasErrorState}
              setHostingGroupName={setHostingGroupName}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default EventInfoForm;
